/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}


.center {
    width: 1200px;
    margin: 0 auto;
}
.header {
    width: 100%;
    background: #fff;
    position: relative;
    overflow: hidden;
    .toptext {
        width: 100%;
        background: url(../img/topbg.jpg) no-repeat top center;
        height: 36px;
        .text {
            .fl {
                p {
                    float: left;
                    color: #fff;
                    font-size: 14px;
                    line-height: 36px;
                    margin-left: 5px;
                }
            }
            .fr {
                a {
                    color: #fff;
                    font-size: 14px;
                    line-height: 36px;
                    @include inline-block();
                    padding-left: 16px;
                    margin-left: 20px;
                    &.sc {
                        background: url(../img/sc.png) no-repeat left center;
                    }
                    &.ly {
                        background: url(../img/ly.png) no-repeat left center;
                    }
                    &.lx {
                        background: url(../img/dz.png) no-repeat left center;
                    }
                }
            }
        }
    }
    .head_top {
        width: 100%;
        .fl {
            padding: 35px 0;
            a {
                float: left;
                img {
                    height: 72px;
                    display: block;
                }
            }
            p {
                float: left;
                padding-left: 10px;
                strong {
                    display: block;
                    font-size: 34px;
                    color: #111;
                    line-height: 100%;
                    margin-top: 3px;
                }
                span {
                    display: block;
                    font-size: 22px;
                    color: #e70012;
                    margin-top: 5px;
                }
            }
        }
        .fr {
            .tel {
                padding: 35px 0;
                padding-left: 55px;
                background: url(../img/tel.png) no-repeat top left;
                span {
                    display: block;
                    font-size: 14px;
                    color: #666;
                    padding-top: 15px;
                }
                p {
                    font-size: 28px;
                    color: #e70012;
                    font-family: impact;
                }
            }
        }
    }
}
.nav {
    width: 100%;
    background: #e70012;
    height: 55px;
    ul {
        li {
            float: left;
            width: 11.11%;
            height: 55px;
            a {
                width: 100%;
                text-align: center;
                line-height: 55px;
                font-size: 16px;
                color: #fff;
                display: block;
                &:hover {
                    background: #1a1a1a;
                }
                &.active {
                    background: #1a1a1a;
                }
            }
            &:first-child {
                padding-left: 10px;
                background: #1a1a1a;
                a {
                    padding-left: 10px;
                    background: url(../img/home.png) no-repeat left center;
                }
            }
        }
    }
}
.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 10000;
    float: none !important;
}

.pc_banner {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .wappc_banner {
        .prev {
            left: 74px;
            width: 43px;
            height: 43px;
            position: absolute;
            top: 48%;
            background: url(../img/le2.png) no-repeat;
            background-size: 100%;
        }
        .next {
            right: 74px;
            width: 43px;
            height: 43px;
            position: absolute;
            top: 48%;
            background: url(../img/ri2.png) no-repeat;
            background-size: 100%;
        }
        .hd {
            width: 100%;
            text-align: center;
            position: absolute;
            z-index: 9;
            bottom: 10px;
            overflow: hidden;
            li {
                width: 40px;
                height: 4px;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                margin: 0 3px;
                overflow: hidden;
                cursor: pointer;
                background: #fff;
                margin: 0 5px;
                &.on {
                    background: #e70012;
                }
            }
        }
        .bd {
            li {
                position: relative;
                img {
                    width: 1920px;
                    height: 597px;
                    position: relative;
                    left: 50%;
                    margin-left: -960px;
                }
            }
        }
    }
}
.m_banner {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: none;
    .mbanner {
        width: 100%;
        position: relative;
        ul {
            li {
                width: 100%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.banner_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 9;
    bottom: 10px;
    overflow: hidden;
    a {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        text-indent: -999px;
        overflow: hidden;
        margin: 0 3px;
        overflow: hidden;
        cursor: pointer;
        background: #eee;
        opacity: .4;
        margin: 0 5px;
        border: solid 2px #FFFFFF;
        &.in {
            background: #e70012;
            opacity: 1;
        }

    }
}



.m_header {
    width: 100%;
    overflow: hidden;
    display: none;
    .m_head_content {
        height: .46rem;
        width: 100%;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        box-shadow: 0px 5px 10px 1px #999;
        .m_head_logo {
            height: .46rem;
            float: left;
            margin-left: .05rem;
            img {
                max-height: 100%;
                float: left;
                height: 70px;
                margin-top: 5px;
            }
            p {
                float: left;
                margin-left: 15px;
                strong {
                    font-size: 28px;
                    line-height: 40px;
                    display: block;
                    color: #c7271e;
                    margin-top: 6px;
                }
                span {
                    font-size: 18px;
                    color: #333;
                    display: block;
                }
            }
        }
        .menu {
            float: right;
            width: .36rem;
            margin-top: .05rem;
            margin-right: .1rem;
            height: .36rem;
            background: #c7271e;
            i {
                color: #fff;
                font-size: 20px;
                text-align: center;
                line-height: .46rem;
                display: block;
            }
        }
    }   
}

.app_menu {
    width: 100%;
    height: 500%;
    position: fixed;
    height: 500%;
    top: 80px;
    right: 0;
    z-index: 99;
    display: none;
    background: rgba(0, 0, 0, .5);
    ul {
        background: rgba(0, 0, 0,1);
        li {
            a {
                span {
                    font-size: 14px;
                    width: 100%;
                    height: 32px;
                    line-height: 32px;
                    color: #fff;
                    display: block;
                    padding-left: .15rem;
                    border-bottom: 1px solid #444;
                }
            }
        }
    }
}

.container {
    width: 100%;
    overflow: hidden;
}
.main {
    width: 100%;
    overflow: hidden;
}
.search {
    width: 100%;
    height: 90px;
    border-bottom: 1px solid #dfdfdf;
    .box {
        .fl {
            span {
                font-size: 14px;
                color: #666;
                line-height: 90px;
                @include inline-block();
            }
            p {
                    @include inline-block();
                a {
                    font-size: 14px;
                    color: #666;
                    line-height: 100%;
                    border-left: 1px solid #666;
                    padding-left: 5px;
                    margin-right: 5px;
                    @include inline-block();
                    &:first-child {
                        border-left: none;
                    }
                    &:hover {
                        color: #e70012;
                    }
                }
            }
        }
        .fr {
            margin-top: 30px;
            .inp1 {
                height: 30px;
                width: 240px;
                border: none;
                border: 1px solid #dfdfdf;
                outline: 0;
                float: left;
                border-right: none;
                padding-left: 10px;
            }
            .inp2 {
                height: 30px;
                width: 100px;
                border: none;
                outline: 0;
                float: left;
                font-size: 14px;
                color: #fff;
                text-align: center;
                line-height: 30px;
                border: 1px solid #e70012;
                background: url(../img/sea.png) no-repeat 90% center #e70012;
            }
        }
    }
}

.box_tit {
    strong {
        font-size: 30px;
        color: #e60012;
        display: block;
        font-weight: bold;
        text-align: center;
        background: url(../img/tit.png) no-repeat center;
    }
    span {
        font-size: 18px;
        color: #333;
        margin-top: 8px;
        display: block;
        text-align: center;

    }
}
.i_box1 {
    width: 100%;
    padding: 40px 0;
    .text {

    }
}
.box1list {
    width: 100%;
    margin-top: 30px;
    .hd {
        width: 100%;
        background: #1f1a17;
        height: 55px;
        margin-bottom: 30px;
        ul {
            li {
                float: left;
                height: 55px;
                border-right: 1px solid #333;
                span {
                    display: block;
                    padding: 0 30px;
                    color: #fff;
                    line-height: 55px;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                }
                &.on {
                    background: #e70012;
                    span {
                        padding-bottom: 4px;
                        background: url(../img/bot.png) no-repeat bottom center;
                    }
                }
            }
        }
    }
    .bd {
        width: 100%;
        ul {
            li {
                width: 100%;
                float: left;
                background: #e70012;
                .fl {
                    width: 61%;
                    img {
                        display: block;
                        width: 100%;
                    }
                }
                .fr {
                    width: 39%;
                    padding: 50px;
                    span {
                        display: block;
                        font-size: 18px;
                        font-weight: bold;
                        color: #fff;
                        border-bottom: 1px solid #e34d59;
                        padding-bottom: 20px;
                    }
                    p {
                        font-size: 14px;
                        color: #fff;
                        display: block;
                        line-height: 32px;
                        margin-top: 20px;
                    }
                    a {
                        width: 176px;
                        height: 44px;
                        display: block;
                        text-align: center;
                        line-height: 44px;
                        font-size: 18px;
                        font-weight: bold;
                        color: #fff;
                        margin-top: 30px;
                        background: url(../img/kuang.png) no-repeat center;
                    }
                }

            }
        }
    }
}

.i_box2 {
    width: 100%;
    background: url(../img/bg1.jpg) no-repeat center top #f4f4f4;
    padding: 40px 0;
    .box2_tit {
        strong {
            font-size: 30px;
            color: #1f1a17;
            display: block;
            text-align: center;
            span {
                font-size: 30px;
                color: #e60012;
                i {
                    font-size: 60px;
                    font-weight: bold;
                    font-style: normal;
                }
            }
        }
        em {
            font-size: 22px;
            color: #333;
            display: block;
            font-style: normal;
            text-align: center;
        }
        p {
            font-size: 12px;
            font-family: arial;
            color: #fff;
            height: 30px;
            width: 600px;
            line-height: 30px;
            text-align: center;
            margin: 0 auto;
            background: #e60012;
            margin-top: 10px;
        }
    }
    .box {
        width: 100%;
        margin-top: 50px;
        ul {
            width: 410px;
            li {
                width: 100%;
                height: 132px;
                background: url(../img/li1.png) no-repeat left center;
                margin-top: 24px;
                i {
                    float: left;
                    width: 107px;
                    height: 107px;
                    margin-top: 12px;
                    img {
                        display: block;
                        margin: 0 auto;
                        height: 28px;
                        margin-top: 39px;
                    }
                }
                .li_cont {
                    width: 55%;
                    float: left;
                    margin-left: 55px;
                    span {
                        font-size: 16px;
                        color: #333;
                        display: block;
                        font-weight: bold;
                        padding-top: 15px;
                    }
                    p {
                        font-size: 13px;
                        color: #333;
                        line-height: 22px;
                        margin-top: 5px;
                    }
                }
                &:hover {
                    background: url(../img/li2.png) no-repeat left center;
                }
            }
        }
        .fl {
            margin-right: 5px;
        }
        .fr {
            li {
                transform: rotate(180deg);
                -ms-transform: rotate(180deg); 
                -webkit-transform: rotate(180deg); 
                -o-transform: rotate(180deg);
                -moz-transform: rotate(180deg); 
                .li_cont {
                    transform: rotate(180deg);
                    -ms-transform: rotate(180deg); 
                    -webkit-transform: rotate(180deg); 
                    -o-transform: rotate(180deg);
                    -moz-transform: rotate(180deg); 
                    position: relative;
                }
            }
        }
        .pic {
            float: left;
            width: 354px;
            margin-left: 8px;
            img {
                display: block;
                width: 100%;
            }
        }
    }
    .text {
        span {
            width: 100%;
            text-align: center;
            display: block;
            margin-top: 30px;
            a {
                width: 176px;
                height: 44px;
                @include inline-block();
                text-align: center;
                line-height: 44px;
                font-size: 18px;
                font-weight: bold;
                margin: 0 10px;
            }
            .a1 {
                color: #fff;
                background: url(../img/kuang3.png) no-repeat center;
            }
            .a2 {
                color: #333;
                background: url(../img/kuang2.png) no-repeat center;
            }
        }
        p {
            text-align: center;
            margin-top: 10px;
            font-size: 16px;
            color: #666;
            font-weight: bold;
            i {
                font-size: 35px;
                color: #e70012;
                font-family: impact;
                font-style: normal;
            }
        }
    }
}

.box3_list {
    width: 75%;
    padding-left: 20px;
    position: relative;
    ul {
        li {
            width: 32%;
            margin-right: 2%;
            float: left;
            margin-bottom: 14px;
            .imgauto {
                width: 100%;
                height: 210px;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
            }
            span {
                display: block;
                font-size: 16px;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
                margin-top: 5px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
            &:hover {
                .imgauto {
                    img {
                        transform: scale(1.08);
                        transition: .5s;
                    }
                }
                span {
                    color: #e70012;
                }
            }
        }
    }
}

.i_box3 {
    width: 100%;
    padding: 40px 0;
    .box {
        width: 100%;
        margin-top: 40px;
    }
}
.main_l {
    width: 25%;
    background: #171310;
    .main_menu {
        h3 {
            height: 80px;
            width: 100%;
            display: block;
            line-height: 80px;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            background: #d70010;
            padding: 0 20px;
        }
        ul {
            padding: 10px 20px;
            span {
                display: block;
                width: 100%;
                height: 45px;
                line-height: 45px;
                font-size: 18px;
                color: #fff;
                background: #4a4645;
                padding: 0 20px;
                margin-top: 10px;
                float: left;
            }
            li {
                width: 50%;
                float: left;
                line-height: 40px;
                padding-left: 10px;
                background: url(../img/dian.png) no-repeat left center;
                border-bottom: 1px dashed #4a4645;
                a {
                    font-size: 16px;
                    color: #fff;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    &:hover {
                        color: #e60012;
                    }
                }
            }
        }
    }
}


.i_box4 {
    width: 100%;
    padding: 40px 0;
    .top_pic {
        width: 100%;
        overflow: hidden;
        img {
            width: 1920px;
            position: relative;
            left: 50%;
            margin-left: -960px;
        }
    }
    .text {
        width: 100%;
        margin-top: 30px;
        ul {
            li {
                width: 100%;
                float: left;
                margin-top: 40px;
                .fl {
                    width: 50%;
                    img {
                        width: 100%;
                        display: block;
                    }
                }
                .fr {
                    width: 50%;
                    img {
                        width: 108%;
                        margin-left: -8%;
                        margin-top: 20px;
                    }
                    strong {
                        display: block;
                        line-height: 54px;
                        padding-left: 8%;
                        i {
                            font-size: 30px;
                            font-weight: bold;
                            color: #e60012;
                            border-bottom: 4px solid #c1c1c1;
                            font-style: normal;
                            padding-bottom: 5px;
                        }
                    }
                    span {
                        padding-left: 8%;
                        font-size: 22px;
                        color: #333;
                        display: block;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                    p {
                        margin-left: 8%;
                        padding-left: 20px;
                        font-size: 15px;
                        color: #333;
                        line-height: 24px;
                        margin-top: 5px;
                        background: url(../img/dian2.png) no-repeat left 7px;
                    }
                }
                &.on {
                    .fl {
                        float: right;
                    }
                    .fr {
                        img {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
.i_box5 {
    width: 100%;
    margin-top: 20px;
    padding: 10px 0;
    background: url(../img/bg3.png);
    .box {
        .text {
            width: 55%;
            float: left;
            margin-top: 40px;
            span {
                display: block;
                font-size: 45px;
                color: #fff;
                font-weight: bold;
            }
            p {
                margin-top: 20px;
                font-size: 18px;
                color: #fff;
                display: block;
            }
        }
        img {
            width: 31%;
            float: right;
        }
    }
}
.i_box6 {
    width: 100%;
    padding: 40px 0;
    .box {
        width: 100%;
        margin-top: 40px;
        .fl {
            width: 38%;
            img {
                display: block;
                width: 100%;
            }
        }
        .fr {
            width: 62%;
            padding-left: 3%;
            strong {
                display: block;
                font-size: 20px;
                color: #e60012;
                font-weight: bold;
                margin-bottom: 15px;
            }
            p {
                font-size: 14px;
                color: #333;
                line-height: 29px;
                text-indent: 2em;
            }
            span {
                width: 100%;
                display: block;
                margin-top: 20px;
                a {
                    width: 176px;
                    height: 44px;
                    @include inline-block();
                    text-align: center;
                    line-height: 44px;
                    font-size: 18px;
                    font-weight: bold;
                    margin-right:  20px;
                }
                .a1 {
                    color: #fff;
                    background: url(../img/kuang3.png) no-repeat center;
                }
                .a2 {
                    color: #333;
                    background: url(../img/kuang2.png) no-repeat center;
                }
            }
        }
    }
}
.i_box7 {
    width: 100%;
    padding: 40px 0;
    background: #f4f4f4;
    .box {
        width: 100%;
        margin-top: 30px;
        .fl {
            width: 65%;
        }
        .fr {
            width: 30%;
            .fr_tit {
                width: 100%;
                border-bottom: 1px solid #ddd;
                margin-bottom: 20px;
                span {
                    font-size: 18px;
                    color: #000;
                    font-weight: bold;
                    float: left;
                    margin-right: 20px;
                    cursor: pointer;
                    border-bottom: 3px solid #333;
                    padding-bottom: 10px;
                }
                a {
                    float: right;
                    line-height: 30px;
                    font-size: 14px;
                    padding-right: 28px;
                    background: url(../img/more.jpg) no-repeat right center;
                    &:hover {
                        color: #e60012;
                    }
                }
            }
            .fr_list {
                width: 100%;
                background: #fff;
                padding-bottom: 10px;
                .bd {
                    width: 100%;
                    padding: 20px;
                    max-height: 379px;
                    overflow: hidden;
                    ul {
                        li {
                            width: 100%;
                            height: 125px;
                            margin-bottom: 10px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid #eee;
                            a {
                                width: 100%;
                                display: block;
                                font-size: 16px;
                                font-weight: bold;
                                color: #333;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                padding-left: 30px;
                                background: url(../img/q.jpg) no-repeat left 3px;
                                &:hover {
                                    color: #e60012;
                                }
                            }
                            p {
                                font-size: 14px;
                                height: 72px;
                                overflow: hidden;
                                color: #333;
                                line-height: 24px;
                                margin-top: 10px;
                                padding-left: 30px;
                                background: url(../img/a.jpg) no-repeat left 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.box7list {
    width: 100%;
    .hd {
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
        ul {
            float: left;
            li {
                font-size: 18px;
                color: #000;
                font-weight: bold;
                float: left;
                margin-right: 20px;
                cursor: pointer;
                border-bottom: 3px solid #f4f4f4;
                padding-bottom: 10px;
                &.on {
                    color: #e60012;
                    border-bottom: 3px solid #e60012;
                }
            }
        }
        a {
            float: right;
            line-height: 30px;
            font-size: 14px;
            padding-right: 28px;
            background: url(../img/more.jpg) no-repeat right center;
            &:hover {
                color: #e60012;
            }
        }
    }
    .bd {
        ul {
            li {
                .fl {
                    width: 47%;
                    background: #fff;
                    img {
                        display: block;
                        width: 100%;
                    }
                    span {
                        display: block;
                        font-size: 16px;
                        color: #333;
                        font-weight: bold;
                        margin: 20px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid #eee;
                        padding-bottom: 10px;
                        &:hover {
                            color: #e60012;
                        }
                    }
                    p {
                        font-size: 14px;
                        color: #555;
                        display: block;
                        line-height: 24px;
                        padding: 0 20px;
                    }
                    i {
                        width: 20px;
                        height: 20px;
                        float: right;
                        margin-top: 15px;
                        font-style: normal;
                        background: url(../img/dian.png) no-repeat center #e60012;
                    }
                }
                .fr {
                    width: 47%;
                    background: #fff;
                    .top {
                        position: relative;
                        img {
                            width: 100%;
                        }
                        span {
                            width: 100%;
                            position: absolute;
                            bottom: 3px;
                            left: 0;
                            height: 40px;
                            background: rgba(0,0,0,.5);
                            font-size: 14px;
                            line-height: 40px;
                            color: #fff;
                            text-align: center;
                        }
                    }
                    .list {
                        width: 100%;
                        padding: 10px 20px;
                        p {
                            width: 100%;
                            float: left;
                            margin: 7px 0;
                            span {
                                width: 20px;
                                height: 20px;
                                font-size: 12px;
                                color: #fff;
                                line-height: 20px;
                                text-align: center;
                                display: block;
                                float: left;
                                background: #8f8c8b;
                            }
                            a {
                                width: 85%;
                                font-size: 14px;
                                color: #333;
                                float: left;
                                display: block;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                padding-left: 10px;
                                &:hover {
                                    color: #e60012;
                                }
                            }
                            &:first-child {
                                span {
                                    background: #e60012;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.footer {
    width: 100%;
    background: #191411;
    padding-top: 10px;
    .foot_nav {
        height: 60px;
        border-bottom: 1px solid #2e2a27;
        .list {
            ul {
                li {
                    width: 11.11%;
                    float: left;
                    a {
                        text-align: center;
                        line-height: 60px;
                        font-size: 16px;
                        color: #929191;
                        display: block;
                    }
                }
            }
        }
    }
    .foot_logo {
        width: 25%;
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
            margin-top: 55px;
        }
    }
    .foot_contact {
        width: 48%;
        float: left;
        padding: 20px;
        padding-bottom: 30px;
        border-left: 1px solid #2e2a27;
        border-right: 1px solid #2e2a27;
        .text2 {
            width: 100%;
            p {
                font-size: 14px;
                color: #929190;
                display: block;
                margin-top: 10px;
                a {
                    font-size: 14px;
                    color: #929190;
                }
                strong {
                    font-size: 24px;
                    color: #e60012;
                    font-weight: bold;
                }
            }
        }
        
    } 
    .foot_ewm {
        width: 25%;
        float: right;
        margin-top: 15px;
        p {
            @include inline-block();
            margin-top: 20px;
            padding: 15px;
            background: #211e1b;
            margin: 0 5px;
            border-radius: 5px;
            width: 47%;
            margin: 0 1%;
            span {
                display: block;
                font-size: 14px;
                text-align: center;
                color: #929190;
                margin-bottom: 8px;
            }
            img {
                display: block;
                width: 100%;
            }
        }
    }
}


.page_banner {
    width: 100%;
    overflow: hidden;
    img {
        width: 1920px;
        height: auto;
        position: relative;
        left: 50%;
        margin-left: -960px;
        z-index: 8;
    }
}

.wrap {
    width: 100%;
    margin: 30px 0;
    #m_main_menu {
        display: none;
        position: relative;
        h3 {
            position: relative;
            z-index: 10;
            border-bottom: .1px solid #c02913;
            i {
                width: 40px;
                height: 40px;
                position: absolute;
                z-index: 9;
                top: 5px;
                right: 10px;
                background: url(../img/ri2.png) no-repeat center;
                background-size: 50%;
            }
            &.on {
                i {
                    transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                }
            }
        }
    }
}
.main_r {
    width: 75%;
    padding-left: 20px;
}
.page_tit {
    width: 100%;
    border-bottom: 1px solid #ddd;
    span {
        font-size: 22px;
        color: #e70012;
        font-weight: bold;
        float: left;
        margin-right: 20px;
        cursor: pointer;
        border-bottom: 3px solid #e70012;
        padding-bottom: 12px;
        float: left;
    }
    p {
        float: right;
        font-size: 13px;
        color: #666;
        padding-left: 20px;
        line-height: 44px;
        background: url(../img/home2.png) no-repeat left center;
        a {
            font-size: 13px;
            color: #666;
            &:hover {
                color: #e70012;
            }
        }
    }
}

.new_list {
    width: 100%;
    margin-top: 20px;
    ul {
        li {
            width: 100%;
            float: left;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
            .fl {
                width: 25%;
                .imgauto {
                    width: 100%;
                    height: 162px;
                    padding: 1%;
                    border: 1px solid #ddd;
                }
            }
            .fr {
                width: 75%;
                padding-left: 20px;
                span {
                    font-size: 18px;
                    color: #000;
                    display: block;
                }
                p {
                    font-size: 14px;
                    color: #888;
                    display: block;
                    margin-top: 10px;
                    line-height: 24px;
                }
                .text {
                    width: 100%;
                    margin-top: 10px;
                    .fenlei {
                        float: left;
                        font-size: 16px;
                        color: #e60012;
                        line-height: 30px;
                    }
                    .timec {
                        float: right;
                        .time {
                            padding-left: 20px;
                            font-size: 14px;
                            color: #333;
                            line-height: 30px;
                            background: url(../img/time.png) no-repeat left center;
                            background-size: 16px;
                            float: left;
                        }
                        .kan {
                            background: url(../img/shu.png) no-repeat left center;
                            background-size: 16px;
                            float: right;
                            padding-left: 20px;
                            font-size: 14px;
                            color: #333;
                            line-height: 30px;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
}











.iet {
    height: 30px;
    width: 100%;
    background: #fff3d7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #333;
        line-height: 30px;
        a {
            color: #3aba50;
            font-size: 12px;
        }
    }
    span {
        position: absolute;
        right: 5%;
        top: 0;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
        i {
            font-size: 12px;
            color: #333;
            padding-left: 5px;
            font-style: normal;
        }
    }
}

.content_tit {
    width: 100%;
    border-bottom: 1px dashed #e9e9e9;
    padding-bottom: 15px;
    h3 {
        display: block;
        text-align: center;
        font-size: 28px;
        color: #333;
        font-weight: bold;
        margin-top: 20px;
    }
    p {
        width: 100%;
        text-align: center;
        span {
            @include inline-block();
            line-height: 50px;
            margin-right: 10px;
            padding-left: 10px;
            margin-top: 10px;
            color: #5a5a5a;
            line-height: 100%;
            &:first-child {
                border-left: none;
            }
        }
    }
}
.content {
    width: 100%;
    padding: 0 1%;
    padding-bottom: 20px;
    p {
        font-size: 14px;
        color: #666;
        display: block;
        line-height: 24px;
        margin-top: 10px;
    }
    img {
        display: block;
        max-width: 100%;
    }
}
.show_pages {
    width: 100%;
    background: #f5f5f5;
    padding: 0 1%;
    p {
        font-size: 14px;
        color: #666;
        line-height: 50px;
        a {
            font-size: 14px;
            color: #666;
            line-height: 50px;
            &:hover {
                color: #ea5534;
            }
        }
    }
    .fl {
        padding-left: 20px;
        background: url(../img/next.png) no-repeat left center;
    }
    .fr {
        padding-right: 20px;
        background: url(../img/prve.png) no-repeat right center;
    }
}
.show_text {
    width: 100%;
    margin-top: 20px;
    h3 {
        border-bottom: 1px solid #ddd;
        display: block;
        margin-bottom: 10px;
        span {
            width: 130px;
            height: 40px;
            display: block;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            color: #fff;
            background: #d70010;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    ul {
        li {
            width: 49%;
            float: left;
            padding-right: 2%;
            padding-left: 15px;
            line-height: 30px;
            background: url(../img/dian2.png) no-repeat left center;
            a {
                font-size: 14px;
                color: #666;
                float: left;
                width: 60%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover {
                    color: #d70010;
                }
            }
            span {
                float: right;
                font-size: 14px;
                color: #999;
            }
            &:nth-child(2n) {
                padding-right: 0;
                float: right;
            }
        }
    }
}
.pro_list {
    width: 100%;
    margin-top: 20px;
    position: relative;
    ul {
        li {
            width: 32%;
            margin-right: 2%;
            float: left;
            margin-bottom: 14px;
            .imgauto {
                width: 100%;
                height: 210px;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
            }
            span {
                display: block;
                font-size: 16px;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
                margin-top: 5px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
            &:hover {
                .imgauto {
                    img {
                        transform: scale(1.08);
                        transition: .5s;
                    }
                }
                span {
                    color: #e70012;
                }
            }
        }
    }
}

.content_box {
    width: 100%;
    margin-top: 20px;
    .fl {
        width: 50%;
        float: left;
        .pic {
            padding: 1%;
            border: 1px solid #ddd;
        }
        img {
            width: 100%;
            display: block;
        }
    }
    .fr {
        width: 47%;
        float: left;
        padding-left: 3%;
        p {
            line-height: 45px;
            height: 45px;
            border-bottom: 1px dashed #ddd;
            font-size: 16px;
            color: #666;
            span {
                font-size: 22px;
                color: #e70012;
                font-family: impact;
            }
        }
        a {
            width: 145px;
            height: 40px;
            background: #e70012;
            font-size: 16px;
            color: #fff;
            display: block;
            text-align: center;
            line-height: 40px;
            margin-top: 20px;
            border-radius: 5px;
        }
    }
}
.show_boxa {
    width: 100%;
    a {
        float: left;
        margin-right: 30px;
        font-size: 14px;
        color: #666;
        line-height: 30px;
        &:hover {
            color: #e70012;
        }
    }
}
.show_text2 {
    width: 100%;
    a {
        float: left;
        margin-right: 30px;
        font-size: 14px;
        color: #666;
        line-height: 30px;
        &:hover {
            color: #e70012;
        }
    }
}



@media screen and (max-width: 1200px){
    .center {
        width: 100%;
    }
    .i_box2 .box .pic {
        display: none;
    }
}

@media screen and (max-width: 1020px){
    .m_header {
        display: block;
    }
    .header {
        display: none;
    }
    .m_header .m_head_content {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo img {
        max-height: 100%;
    }
    .m_header .m_head_content .menu {
        height: 70px;
        width: 70px;
        margin-top: 5px;
        margin-right: 10px;
    }
    .m_header .m_head_content .menu i {
        height: 70px;
        line-height: 70px;
    }
    .app_menu ul li a span {
        font-size: 14px;
        height: 42px;
        line-height: 42px;
    }
    .mtbanner {
        padding-top: 80px;
    }
    .mtop {
        padding-top: 80px;
    }
    .page_banner {
        padding-top: 80px;
    }
    .page_banner img {
        width: 100%;
        height: auto;
        left: 0;
        margin-left: 0;
    }
    .pc_banner {
        display: none;
    }
    .m_banner {
        display: block;
    }
    .i_box3 .box .fl {
        display: none;
    }
    .box3_list {
        width: 100%;
        padding-left: 0;
    }
    .i_box4 {
        padding-top: 10px;
    }
    .i_box4 .text ul li .fr img {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
    }
    .i_box7 .box .fl {
        width: 100%;
    }
    .i_box7 .box .fr {
        width: 100%;
        margin-top: 10px;
    }
    .main_l {
        width: 100%;
    }
    .main_r {
        width: 100%;
        margin-top: 10px;
        padding: 0 5px;
    }
    .main_l .main_menu h3 {
        font-size: 20px;
        height: 50px;
        line-height: 50px;
    }
    .main_l .main_menu ul li {
        width: 25%;
    }
    .page_tit p {
        display: none;
    }
    #pc_main_menu {
        display: none;
    }
    .wrap #m_main_menu {
        display: block;
    }
    .wrap {
        margin: 10px 0;
    }
    
}




@media screen and (max-width: 850px) {
    .box1list .hd ul li {
        width: 20%;
    }   
    .box1list .hd ul li span {
        padding: 0;
        text-align: center;
    }   
    .box1list .bd ul li .fl {
        width: 100%;
    }
    .box1list .bd ul li .fr {
        width: 100%;
        padding: 20px;
    }
    .search {
        height: auto;
    }
    .search .box .fl {
        display: none;
    }
    .search .box .fr {
        width: 100%;
        margin: 10px 0;
    }
    .search .box .fr .inp1 {
        width: 70%;
        height: 40px;
        line-height: 40px;
    }
    .search .box .fr .inp2 {
        width: 30%;
        height: 40px;
        line-height: 40px;
    }
    .i_box2 .box ul {
        width: 100%;
    }
    .i_box2 .box ul li .li_cont {
        width: 230px;
    }
    .i_box4 .text ul li .fl {
        width: 100%;
    }
    .i_box4 .text ul li .fr {
        width: 100%;
    }
    .i_box4 .text {
        margin-top: 10px;
    }
    .i_box5 .box .text {
        width: 100%;
        padding: 0 5px;
        margin-top: 0;
    }
    .i_box5 .box img {
        display: none;
    }
    .i_box5 .box .text span {
        font-size: 24px;
    }
    .i_box5 .box .text p {
        font-size: 14px;
    }
    .box7list .bd ul li .fl {
        display: none;
    }
    .box7list .bd ul li .fr {
        width: 100%;
    }
    .footer .foot_logo {
        display: none;
    }
    .footer .foot_ewm {
        width: 40%;
    }
    .footer .foot_nav{
        display: none;
    }
    .footer .foot_contact {
        width: 55%;
    }

}



@media screen and (max-width: 585px){
    .m_header  {
        .m_head_content {
            height: .46rem;
            .m_head_logo {
                height: .4rem;
                img {
                    margin-top: .03rem;
                }
                p {
                    margin-left: 5px;
                    strong {
                        font-size: .16rem;
                        line-height: 100%;
                        margin-top: .07rem;
                    }
                    span {
                        font-size: .11rem;
                        margin-top: .03rem;
                    }
                }
            }
            .qiehuan {
                display: block;
            }
            .menu {
                width: .36rem;
                height: .36rem;
                margin-top: .05rem;
                margin-right: .1rem;
                border-radius: 5px;
                i {
                    height: .36rem;
                    line-height: .36rem;
                }
            }
        }
    }
    .mtbanner {
        padding-top: .46rem;
    }
    .mtop {
        padding-top: .46rem;
    }
    .page_banner {
        padding-top: .46rem;
    }
    .app_menu {
        top: .46rem;
    }
    .box_tit strong {
        background: url(../img/tit.png) no-repeat center;
        background-size: 200%;
        font-size: 24px;
    }
    .box_tit span {
        font-size: 16px;
        margin-top: 5px;
    }
    .search {
        padding: 0 5px;
    }
    .i_box2 {
        padding: 20px 0;
    }
    .i_box2 .box2_tit strong {
        font-size: 24px;
    }
    .i_box2 .box2_tit strong span {
        font-size: 24px;
    }
    .i_box2 .box2_tit strong span i {
        font-size: 40px;
    }
    .i_box2 .box2_tit em {
        font-size: 16px;
    }
    .i_box2 .box2_tit p {
        display: none;
    }
    .i_box2 .box {
        margin-top: 20px;
    }
    .box3_list ul li {
        width: 48%;
        margin: 0 1%;
        margin-bottom: 15px;
    }
    .box3_list ul li:nth-child(3n) {
        margin-right: 1%;
    }
    .box3_list ul li:hover .imgauto img{
        transform: scale(1);
        transition: .5s;
    }
    .i_box6 .box .fl {
        width: 100%;
    }
    .i_box6 .box .fr {
        width: 100%;
        padding: 0 5px;
    }
    .i_box6 .box .fr p {
        text-indent: 0;
    }
    .i_box6 .box .fr span a {
        width: 47%;
        margin: 0 1%;
    }
    .i_box6 .box .fr span .a1 {
        background-size: 100% 100%;
    }
    .i_box6 .box .fr span .a2 {
        background-size: 100% 100% ;
    }
    .footer .foot_contact {
        width: 100%;
    }
    .footer .foot_ewm {
        width: 100%;
    }
    .new_list ul li .fl {
        width: 30%;
    }
    .new_list ul li .fr {
        width: 70%;
    }
    .show_pages p {
        line-height: 40px;
    }
    .show_pages p a {
        line-height: 40px;
    }
    .show_pages .fl {
        width: 100%;
    }
    .show_pages .fr {
        width: 100%;
        padding-right: 0;
        padding-left: 20px;
        background: url(../img/prve.png) no-repeat left center;
    }
    .show_text ul li {
        width: 100%;
        padding-right: 0;
    }
    .show_text ul li:nth-child(2n) {
        padding-right: 0;
    }
    .pro_list ul li {
        width: 48%;
        margin: 0 1%;
        margin-bottom: 20px;
    }
    .pro_list ul li:nth-child(3n) {
        margin-right: 1%;
    } 
    .content_box .fl {
        width: 100%;
    }
    .content_box .fr {
        width: 100%;
        padding-left: 0;
        margin-top: 1px;
    }

}


@media screen and (max-width: 450px) {
    .box1list .hd ul li span {
        font-size: 13px;
    }
    .i_box1 {
        padding: 15px 0;
    }
    .box1list {
        margin-top: 20px;
    }
    .box1list .bd ul li .fr {
        padding: 10px;
    }
    .box1list .bd ul li .fr span {
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .box1list .bd ul li .fr p {
        font-size: 14px;
        line-height: 24px;
        margin-top: 10px;
    }
    .box1list .bd ul li .fr a {
        width: 130px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        margin-top: 10px;
        background-size: 100%;
    }
    .i_box1 {
        padding-bottom: 0;
    }
    .i_box2 .box2_tit strong {
        font-size: 22px;
    }
    .i_box2 .box2_tit strong span {
        font-size: 22px;
    }
    .i_box2 .box2_tit strong span i {
        font-size: 40px;
    }
    .i_box2 .box {
        padding: 0 5px;
    }
    .i_box2 .box ul li {
        background-size: 100%;
        height: 134px;
        margin-top: 10px;
    }
    .i_box2 .box ul li .li_cont {
        width: 55%;
        margin-left: 11%;
    }
    .i_box2 .box ul li i {
        width: 26.5%;
        height: auto;
    }
    .i_box2 .box ul li:hover {
        background: url(../img/li2.png) no-repeat left center;
        background-size: 100%;
    }
    .i_box2 .text span a {
        width: 45%;
        margin: 0 2%;
        background-size: 100%;
    }
    .i_box2 .text span .a1 {
        background-size: 100%;
    }
    .i_box2 .text span .a2 {
        background-size: 100%;
    }
    .i_box2 .text p i {
        font-size: 30px;
    }
    .box3_list ul li .imgauto {
        height: 150px;
    }
    .i_box3 .box {
        margin-top: 20px;
    }
    .box3_list ul li span {
        font-size: 14px;
    }
    .i_box4 .text ul li {
        margin-top: 10px;
    }
    .i_box4 .text ul li .fr {
        padding: 0 5px;
        margin-top: 10px;
    }
    .i_box4 .text ul li .fr strong {
        line-height: 40px;
        padding-left: 10px;
    }
    .i_box4 .text ul li .fr strong i {
        font-size: 24px;
    }
    .i_box4 .text ul li .fr span {
        font-size: 18px;
        padding-left: 10px;
    }
    .i_box4 .text ul li .fr p {
        font-size: 14px;
        margin-left: 10px;
    }
    .i_box4 .top_pic img {
        width: 1400px;
        margin-left: -700px
    }
    .i_box5 .box .text span {
        text-align: center;
    }
    .i_box5 .box .text p {
        margin-top: 10px;
        line-height: 24px;
    }
    .box7list {
        padding: 0 5px;
    }
    .i_box7 .box .fr {
        padding: 0 5px;
    }
    .box7list .bd ul li .fr .list {
        padding: 10px;
    }

    .footer .foot_contact {
        width: 100%;
        padding: 10px 5px;
    }
    .footer .foot_ewm {
        padding-bottom: 20px;
    }
    .footer .foot_ewm p img {
        max-width: 108px;
        margin: 0 auto;
    }
    .main_l .main_menu ul span {
        height: 36px;
        line-height: 36px;
        font-size: 16px;
    }
    .main_l .main_menu ul li {
        width: 50%;
    }
    .main_l .main_menu ul li a {
        font-size: 14px;
    }
    .main_l .main_menu h3 {
        font-size: 18px;
    }
    .new_list ul li .fl {
        width: 35%;
    }
    .new_list ul li .fr {
        width: 65%;
        padding-left: 10px;
    }
    .new_list ul li .fr p {
        display: none;
    }
    .new_list ul li .fr .text .fenlei {
        width: 100%;
    }
    .new_list ul li .fr .text .timec {
        width: 100%;
        float: left;
    }
    .new_page_tit {
        display: none;
    }
    .content_tit h3 {
        font-size: 24px;
        margin-top: 5px;
    }
    .pro_list ul li {
        margin-bottom: 10px;
    }
    .pro_list ul li .imgauto {
        height: 145px;
    }
    .pro_list ul li span {
        font-size: 14px;
    }
    .show_boxa a {
        margin-right: 20px;
    }
    .show_text2 a {
        margin-right: 20px;
    }
    .content_box .fr a {
        width: 130px;
    }
}

@media screen and (max-width: 350px) {
    .i_box2 .box ul li {
        height: 105px;
    }
    .i_box2 .box ul li .li_cont {
        width: 62%;
    }
    .i_box2 .box ul li .li_cont span {
        font-size: 14px;
        padding-top: 10px;
    }
    .i_box2 .box ul li .li_cont p {
        font-size: 12px;
        line-height: 18px;
        margin-top: 2px;
    }

}

/****************************** media ******************************/